/* jshint esversion: 6 */
/* global $:false */

import DebugUtils from '../common/debug'
const _debug = DebugUtils({debug: false}).log;

let _d = function (message) {
    _debug('HashHandler:: ' + message);
};

let WidgetAnchorHashHandler = function(config){

    _d(JSON.stringify(config, null, 4));
    let clearMainMenu = function (menuItemSelector, linkSelector, activeClass) {
        $(menuItemSelector).each(function(){
            $(this).find(linkSelector).removeClass(activeClass)
        });
    };

    let activateMainMenuItem = function (menuItemSelector, linkSelector, activeClass) {
        $(menuItemSelector).each(function(){
            $(this).find(linkSelector).addClass(activeClass)
        });
    };

    let checkForHash = function () {
        let hash = window.location.hash;
        if (hash === config.hash) {
            _d(`hash [${hash}] matched`);
            clearMainMenu(config.menuItemSelectorGeneral, config.linkSelector, config.activeClass)
            activateMainMenuItem(config.menuItemSelectorHash , config.linkSelector, config.activeClass)
        } else {
            _d(`hash [${hash}] unmatched`);
        }
    };

    let updateMenu = function () {
        _d(`update menu for hash [${config.hash}]`);
        clearMainMenu(config.menuItemSelectorGeneral, config.linkSelector, config.activeClass)
        activateMainMenuItem(config.menuItemSelectorHash , config.linkSelector, config.activeClass)
    };

    return {
        checkForHash: checkForHash,
        updateMenu: updateMenu,
        config: config
    };

};


export default WidgetAnchorHashHandler;
