/* jshint esversion: 6 */

import WidgetAnchorHashHandler from '../widgets/WidgetAnchorHashHandler';

let anchorHashHandler = WidgetAnchorHashHandler({
    hash: '#events', // always prepend the #
    menuItemSelectorGeneral: '#menu-primary .nav-item',
    menuItemSelectorHash: '#menu-primary .nav-item.anchor.events',
    linkSelector: '.nav-link',
    activeClass: 'active'
});

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Menu
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Check for a hash when page loads

anchorHashHandler.checkForHash();

// Check for a hash when a specific main menu item is clicked

let anchorLink = document.querySelector(anchorHashHandler.config.menuItemSelectorHash + ' ' + anchorHashHandler.config.linkSelector);

if (anchorLink) {
    anchorLink.addEventListener('click', function(){
        anchorHashHandler.updateMenu();
    });
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Menu - Dialog
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

let anchorHashHandlerDialog = WidgetAnchorHashHandler({
    hash: '#events', // always prepend the #
    menuItemSelectorGeneral: '#main-menu-dialog .nav-item',
    menuItemSelectorHash: '#main-menu-dialog .nav-item.anchor.events',
    linkSelector: '.nav-link',
    activeClass: 'active'
});

// Check for a hash when page loads

anchorHashHandlerDialog.checkForHash();

// Check for a hash when a specific main menu item is clicked

let anchorLinkDialog = document.querySelector(anchorHashHandlerDialog.config.menuItemSelectorHash + ' ' + anchorHashHandlerDialog.config.linkSelector);

if (anchorLinkDialog) {
    anchorLinkDialog.addEventListener('click', function(){
        anchorHashHandlerDialog.updateMenu();
        $('#main-menu-dialog').dialog('close');
    });
}
